import { render, staticRenderFns } from "./sportbetting.vue?vue&type=template&id=7fb2428a&scoped=true&"
import script from "./sportbetting.vue?vue&type=script&lang=js&"
export * from "./sportbetting.vue?vue&type=script&lang=js&"
import style0 from "./sportbetting.vue?vue&type=style&index=0&id=7fb2428a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fb2428a",
  null
  
)

export default component.exports